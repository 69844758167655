import React, { useEffect } from "react";
import './Stories.css';

function Stories() {

    useEffect(() => {window.scrollTo(0, 0)})

    return (
        <div className="Stories">
            <main>
                <div className="grid-container">
                    <div className="grid-item" style={{width: "100%"}}>
                        <h1>
                            Geschichten
                        </h1>
                        <p>
                            Die Geschichten von Pieper & Paulus findest du auf <a style={{color: "white"}}href="https://www.youtube.com/channel/UCP1ZT-RxRCsPAG-k86ejlGg">Youtube</a>, <a style={{color: "white"}}href="https://podcasts.apple.com/de/podcast/pieper-und-paulus-geschichten-f%C3%BCr-kinder-von-5-bis-10/id1737887375">Apple Podcasts</a> oder <a style={{color: "white"}}href="https://open.spotify.com/show/4iXKH7U1zODcNEpWkHzGso">Spotify</a>.
                        </p>
                    </div>
                    <div className="grid-item" style={{width: "100%"}}>
                        <h1>
                            Playlist
                        </h1>
                        <p>
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/videoseries?si=BV4gBM3S-YCssR5O&amp;list=PLfprt-mAeBNmDQTSTjMnWzWoI6MttQrA0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </p>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default Stories;