import React, { useEffect } from 'react';
import './Navbar.css';
import { Link, useLocation } from 'react-router-dom';

function Navbar() {

    var location = useLocation();

    useEffect(() => {console.debug(location)})

    return (
        <header className="header">
            <nav className='navbar'>
                <ul>
                    <li className="logo">
                        <Link to="/">
                            <a>
                                <img src={'../../images/pieper_large.png'} alt="Logo" width={"250px"} />
                                <h1 style={{ translate: "0 -40px", margin: "-20px", rotate: "5deg", width: "40%" }}>
                                    Pieper & Paulus
                                </h1>
                            </a>        
                        </Link>
                    </li>
                    <li className="menuitem" style={location.pathname === "/geschichten" ? {rotate: "2deg", scale:"1.1"} : {} }>
                        <Link to="/geschichten">   
                            <a>
                                Geschichten
                            </a>
                        </Link>
                    </li>
                    {/*
                    <li className="menuitem">    
                        <Link to="/fans">   
                            <a>
                                Fan-Bereich
                            </a>
                        </Link>
                    </li>
                    */}
                    <li className="menuitem" style={location.pathname === "/unterstutzung" ? {rotate: "2deg", scale:"1.1"} : {} }>
                        <Link to="/unterstutzung">   
                            <a>
                                Unterstützung
                            </a>
                        </Link>
                    </li>
                    <li className="menuitem" style={location.pathname === "/uber" ? {rotate: "2deg", scale:"1.1"} : {} }>
                        <Link to="/uber">
                            <a>
                                Über den Podcast
                            </a>
                        </Link>
                    </li>
                    <li className="menuitem" style={location.pathname === "/kontakt" ? {rotate: "2deg", scale:"1.1"} : {} }>
                        <Link to="/kontakt">
                            <a>
                                Kontakt
                            </a>
                        </Link>
                    </li>
                </ul>
            </nav>
            {/*
            <Link className="sticker" to="/fans">
                <img src={'../../images/sticker.png'} alt="Fanzone Sticker" width={"150px"} />
            </Link>
            */}
        </header>
    );
};

export default Navbar;