import React from "react";
import './Fanzone.css';

function Fanzone() {

    return (
        <div className="Fanzone">
            <main>
                <div className="grid-container">
                    <div className="grid-item" style={{width: "100%"}}>
                        <h1>
                            Fanzone
                        </h1>
                        <p>
                            Bald gibt es hier mehr zu sehen!
                        </p>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default Fanzone;