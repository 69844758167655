import React, { useEffect } from "react";
import './Support.css';

function Support() {
    useEffect(() => {window.scrollTo(0, 0)})

    return (
        <div className="Support">
            <main>
                <div className="grid-container">
                    <div className="grid-item" style={{width: "100%"}}>
                        <div className="grid-container-2">
                            <div style={{"gridArea": "1 / 1 / 2 / 5"}}>
                                <h1>
                                    Unterstützung
                                </h1>
                                <p>
                                    Unterstütze Pieper & Paulus durch eine einmalige oder wiederkehrende Spende!
                                </p>
                            </div>
                            
                            <div style={{"gridArea": "2 / 2 / 3 / 3", margin: "auto"}}>
                                <a href="https://buymeacoffee.com/pieperundpaulus"><img src="images/bmc_button.png" width="180px"/></a>
                            </div>

                            <div style={{"gridArea": "2 / 3 / 3 / 4", margin: "auto"}}>
                                <form action="https://www.paypal.com/donate" method="post" target="_top">
                                    <input type="hidden" name="hosted_button_id" value="6PWYJWV6RRC8W" />
                                    <input type="image" src="images/paypal_button.png" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Spenden mit dem PayPal-Button" width={"260px"}/>
                                    <img alt="" border="0" src="https://www.paypal.com/de_DE/i/scr/pixel.gif" width="1" height="1" />
                                </form>
                            </div>
                        </div>
                        <br/>
                        <p>
                            Oder kaufe ein T-Shirt, Sticker und vieles mehr von den beiden bei
                        </p>
                        <a href="https://pieper-und-paulus.myspreadshop.de/"><img src="images/spreadshirt-logo.png" width="180px" style={{borderRadius: "10px"}}/></a>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default Support;