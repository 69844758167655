import React, { useEffect } from "react";
import './Contact.css';

function Contact() {
    useEffect(() => {window.scrollTo(0, 0)})

    return (
        <div className="Stories">
            <main>
                <div className="grid-container">
                    <div className="grid-item" style={{width: "100%"}}>
                        <h1>
                            Kontakt
                        </h1>
                        <p>
                            Pieper & Paulus (und auch der Autor) freuen sich immer sehr über deine Fragen oder Kommentare!
                            <br/><br/>
                            Falls du Pieper oder Paulus elektronische Post senden möchtest, sende sie an:
                        </p>
                        <h4>
                            <p>
                                <a href="mailto:pieper@pieperundpaulus.de" style={{color: "white"}}>
                                    pieper@pieperundpaulus.de
                                </a>
                            </p>
                        </h4>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default Contact;