import React from 'react';
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom';
import Home from './Content/Home';
import Stories from './Content/Stories';
import Navbar from './Navigation/Navbar';
import Footer from './Navigation/Footer';
import Fanzone from './Content/Fanzone';
import Support from './Content/Support';
import About from './Content/About';
import Contact from './Content/Contact';
import Impressum from './Legal/Impressum';
import Disclaimer from './Legal/Disclaimer';

function App() {
    return (
        <div>
            <Navbar />
            <Routes>
                <Route path="/" element={ <Home /> } />
                <Route path="/geschichten" element={ <Stories /> } />
                <Route path="/fans" element={ <Fanzone /> } />
                <Route path="/unterstutzung" element={ <Support /> } />
                <Route path="/uber" element={ <About /> }/>
                <Route path="/kontakt" element={ <Contact /> } />
                <Route path="/impressum" element={ <Impressum /> } />
                <Route path="/disclaimer" element={ <Disclaimer /> } />
            </Routes>
            <Footer />
        </div>
    );
};

export default App;