import React, { useEffect } from "react";
import './About.css';

function About() {
    useEffect(() => {window.scrollTo(0, 0)})

    return (
        <div className="About">
            <main>
                <div className="grid-container">
                    <div className="grid-item" style={{width: "100%"}}>
                        <h1>
                            Über den Podcast
                        </h1>
                        <p>
                            In diesem Kinderpodcast (den auch Erwachsene hören dürfen) hört ihr die tollen, spannenden, lustigen und (meistens) sogar auch lehrreichen Kindergeschichten von Pieper und Paulus. Und jede Woche (spätestens ab Sonntag) erwartet euch hier ein neues kleines Hörspiel von den beiden!
                            <br/><br/>
                            Pieper ist klein, fröhlich, neugierig - und er ist: ein Vogel. Und zwar einer, der sprechen kann! Paulus - das ist Piepers bester Freund: Antonius Paulus, Kriminalkommissar im Ruhestand. Paulus ist kein Vogel, sondern ein Mensch. Und zwar ein lieber, geduldiger und freundlicher Mensch. Wenn ihr erstmal genauer hören wollt, wie die beiden aussehen und wo ihre Geschichten spielen, dann hört euch die Episode "Vorstellungsrunde" an.
                            <br/><br/>
                            Die beiden erleben jede Menge Abenteuer: mal geht es um Freundschaft, Ehrlichkeit und Mitgefühl. Oder es geht um Umweltschutz, um "Geisternetze", um Wespen oder darum, seinen Müll nicht in die Natur zu schmeißen. Pieper und Paulus erfahren, wie toll Fahrradfahren ist und wie schön es aber auch ist, einfach mal zu Fuß zu gehen. Mal geht es um Mobbing oder um die Frage, ob ein Buch besser ist, als ein Smartphone. In manchen Geschichten müssen Pieper und Paulus auch als richtige Detektive arbeiten - oder sie lernen zum Beispiel, wie ein Heißluftballon funktioniert. Mal erfährt Pieper, wer "Sankt Martin" war, wieso man Ostern feiert und was der "Vatertag" eigentlich ist. Oder die beiden begegnen einfach einem "Seepferdchen", das gerne mal schwindelt. Ihr seht, es ist für jeden was dabei: für Mädchen und Jungen im Grundschulalter, aber auch für die ganze Familie! Die Geschichten eignen sich nämlich auch richtig prima, um sie gemeinsam mit Mama, Papa, Oma oder Opa anzuhören! Lehrerinnen und Lehrer dürfen die Hörspiele übrigens auch sehr gerne im Unterricht in der Grundschule verwenden.
                            <br/><br/>
                            Und - ja, das schreiben alle, deshalb schreib ich es auch: vergesst bitte nicht, den Podcast zu liken, zu teilen, zu abonnieren und weiterzuempfehlen. Denn nur so können auch andere Kinder mitbekommen, dass es "Pieper und Paulus" überhaupt gibt! :-)
                        </p>
                    </div>
                    <div className="grid-item" style={{width: "100%"}}>
                        <h1>
                            Über den Autor
                        </h1>
                        <p>
                        Mein Name ist Jan Rimpl, ich bin im Hauptberuf Fernsehjournalist und Autor - und Pieper und Paulus, die beiden kenne ich eigentlich schon sehr lange! Schon in den 90er Jahren, als meine eigenen vier Kinder klein waren, habe ich ihnen Geschichten von den beiden erzählt. Die Abenteuer von "Pieper und Paulus" liefen damals auch sehr erfolgreich im Radio, in der richtig tollen Sendung "Kinderzeit" der Redakteurin Brigitte Petereit bei Radio Bremen. Die Sendung gibt es leider schon lange nicht mehr, ich arbeite schon lange für andere (öffentlich-rechtliche) Sender und meine Kinder sind mittlerweile auch schon lange erwachsen. Aber: Pieper und Paulus sind noch da - oder besser gesagt: sie sind wieder da! Mit dem Podcast, mit diesen neuen, kleinen Hörspielen, erfülle ich mir den lange gehegten und ganz privaten Herzenswunsch, wieder schöne Kinder-Geschichten zu erzählen, die lustig sind und lehrreich, ruhig und trotzdem spannend, werbefrei, zum besten Preis (nämlich kostenlos) und trotzdem richtig ausgezeichnet, so dass sie jeder (unabhängig davon, wie dick die Geldbörse ist) hören kann!
                        <br/><br/>
                        So - und jetzt macht es euch gemütlich und legt los (oder eher: hört los)!
                        <br/><br/>
                        Ich wünsche euch viel Spaß!!! :-)
                        <br/><br/>
                        Liebe Grüße 
                        <br/>
                        Euer Jan
                        </p>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default About;